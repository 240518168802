<div class="rule-text-wrapper">
  <div class="key">
    @if (showKeyTooltip) {
      <dpa-tooltip
        [tooltipTemplate]="keyTooltipTmpl"
        class="column-label"
      >
        <ng-container [ngTemplateOutlet]="tooltipTextTemplate" />
      </dpa-tooltip>
    } @else {
      <dpa-ellipsis-with-tooltip>
        <ng-container [ngTemplateOutlet]="tooltipTextTemplate" />
      </dpa-ellipsis-with-tooltip>
    }
  </div>
  <div class="condition">
    {{ condition | uppercase }}
  </div>
  <div class="value">
    @if (valueCount === 1 && showValuesCount) {
      {{ value }}
    }

    @if (showValuesCount) {
      <div
        [hidden]="valueCount === 1"
        (click)="handleValuesClick()"
        class="values-wrapper ph-2 clickable"
      >
        {{ valueCount }}
        {{ 'COMMON_MESSAGES.VALUES' | translate }}
      </div>
    } @else if (splitValue.length) {
      <dpa-tooltip [tooltipText]="fullValuesText">{{ value }}</dpa-tooltip>
    } @else {
      {{ value }}
    }
  </div>
</div>

<ng-template #tooltipTextTemplate>
  {{ key }}
  <div>
    @if (rule?.attribute | isAttributeNormalized: allColumnsByName : columnToggleFilterMap) {
      @if (showMetadataAsLabel) {
        <span class="label ml-x1 mr-0">
          {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
        </span>
      } @else {
        ({{ 'COMMON_MESSAGES.NORMALIZED' | translate }})
      }
    }
    @if (rule?.attribute | isAttributeRaw: columnToggleFilterMap) {
      @if (showMetadataAsLabel) {
        <span class="label ml-x1 mr-0">
          {{ 'COMMON_MESSAGES.RAW' | translate }}
        </span>
      } @else {
        ({{ 'COMMON_MESSAGES.RAW' | translate }})
      }
    }
  </div>
</ng-template>

<ng-template #keyTooltipTmpl>
  <div class="key-tooltip-wrapper">
    <div>
      @if (keyTooltipInfo?.entity) {
        <span>
          {{ this.keyTooltipInfo?.entity }}
          <cds-icon
            shape="arrow"
            size="16"
          />
        </span>
      }
      <span>
        {{ this.keyTooltipInfo?.label }}
      </span>
    </div>
  </div>
</ng-template>
